import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"

import { projectsList } from "../hooks"
import componentStyles from "./projects.module.scss"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import ProjectCard from "../components/ProjectCard/ProjectCard"
import ProjectsIllustration from "../assets/images/projects-illustration.svg"
import {
  getThumbnailByLink,
  useProjectsCardsThumbnails,
} from "../hooks/useProjectsCardsThumbnails"
import LearnMoreSection from "../components/LearnMoreSection/LearnMoreSection"
import PresentationForm from "../components/PresentationForm/PresentationForm"

const Projects: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useProjectsCardsThumbnails()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )

  const projectsClasses = classNames(componentStyles.projects, "container")

  const projectsListClasses = classNames(componentStyles.projectsList, "grid-2")
  const projectsTitleClasses = classNames(
    componentStyles.projectsTitle,
    "large-section-title"
  )

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({ id: "projects.seo.description" })}
        title={intl.formatMessage({ id: "projects.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <h1 className={componentStyles.bannerTitle}>
            {intl.formatMessage({ id: "projects.banner.title" })}
          </h1>
          <div className={componentStyles.bannerImage}>
            <img
              src={ProjectsIllustration}
              alt="Illustration with desktop users and infrastructure"
              width="513"
              height="407"
            />
          </div>
          <div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({ id: "projects.banner.paragraph-1" })}
            </div>
            <div className={componentStyles.bannerText}>
              {intl.formatMessage({ id: "projects.banner.paragraph-2" })}
            </div>
            <PresentationForm/>
          </div>
        </div>
      </section>
      <section className={projectsClasses}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "projects.projects.title" })}
        </h2>
        <ul className={projectsListClasses}>
          {projects.map(({ title, theme, link }) => {
            const thumbnail = getThumbnailByLink(link, projectsThumbnails)
            return (
              <li
                className={componentStyles.projectsItem}
                key={link}
                tabIndex={0}
              >
                <ProjectCard
                  title={title}
                  theme={theme}
                  thumbnail={thumbnail}
                  link={link}
                />
              </li>
            )
          })}
        </ul>
      </section>
    </Layout>
  )
}

export default Projects
